import React from 'react'
import OurIndustries from '../common/OurIndustries'
import OurBoard from '../common/OurBoard'
import OurSector from '../common/OurSector'
import AboutUsBanner from '../common/AboutUsBanner'

const OurGroupPage=()=> {
  return (
    <>
        <AboutUsBanner/>
        <OurIndustries/>
        <OurBoard/>
        <OurSector/>
    </>
  )
}

export default OurGroupPage
