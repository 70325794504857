import React from "react";
import { home_page_footer_details, left_social_media_footer, right_social_media_footer } from "../Data";

function FooterPage() {
  return (
    <>
      <div>
        <section className="footer_section">
          <div className="container">
            <div className="footer_part">
              <div className="company_information">
                <div className="footer_logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </div>
                <div className="headquarters" id="headquarters_telephone_email">
                  <h2>{home_page_footer_details.location}</h2>
                  <h6>
                    {home_page_footer_details.address}
                  </h6>
                </div>
                <div className="telephone" id="headquarters_telephone_email">
                  <h2>{home_page_footer_details.telephone}</h2>
                  <h6>{home_page_footer_details.telephoneNumber}</h6>
                </div>
                <div className="email" id="headquarters_telephone_email">
                  <h2>{home_page_footer_details.mail}</h2>
                  <h6>{home_page_footer_details.mailId}</h6>
                </div>
              </div>
              <div className="company_information">
                <div
                  className="Our_sector_information"
                  id="footer_information_title"
                >
                  <h2>Our Sector</h2>
                </div>
                <div className="our_sector_links" id="footer_links">
                  <div>
                    <a href="#">Defence</a>
                  </div>
                  <div>
                    <a href="#"> Oil &amp; Gas</a>
                  </div>
                  <div>
                    <a href="#"> Airmotive</a>
                  </div>
                  <div>
                    <a href="#"> Agriculture</a>
                  </div>
                  <div>
                    <a href="#">Automotive</a>
                  </div>
                  <div>
                    <a href="#"> Modular Buildings</a>
                  </div>
                  <div>
                    <a href="#"> Leisure</a>
                  </div>
                  <div>
                    <a href="#">Transport</a>
                  </div>
                </div>
              </div>
              <div className="company_information">
                <div
                  className="Our_companies_information"
                  id="footer_information_title"
                >
                  <h2>Our Companies</h2>
                </div>
                <div className="our_companies_links" id="footer_links">
                  <div>
                    <a href="#">Rhino Defence</a>
                  </div>
                  <div>
                    <a href="#">Tony Beal Ltd</a>
                  </div>
                  <div>
                    <a href="#"> Rhino</a>
                  </div>
                  <div>
                    <a href="#"> Sport &amp; Play</a>
                  </div>
                  <div>
                    <a href="#"> Graffix Detail</a>
                  </div>
                  <div>
                    <a href="#"> CBR</a>
                  </div>
                </div>
              </div>
              <div className="company_information">
                <div
                  className="Our_legal_information"
                  id="footer_information_title"
                >
                  <h2>Legal</h2>
                </div>
                <div className="our_legal_links" id="footer_links">
                  <div>
                    <a href="#">Privacy Policy</a>
                  </div>
                  <div>
                    <a href="#"> Terms &amp; Conditions</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bootom_section">
          <div className="container">
            <div className="footer_bottom">
              <div className="social_media_information">
                <img
                  src={left_social_media_footer.facebookImg}
                  alt="facebook"
                />
                <img
                  src={left_social_media_footer.linkdinImg}
                  alt="linkdin"
                />
              </div>
              <div className="footer_right_bottom">
                <img
                  src={right_social_media_footer.img1}
                />
                <img
                  src={right_social_media_footer.img2}
                />
                <img
                  src={right_social_media_footer.img3}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default FooterPage;
