import React from "react";
import { Link } from "react-router-dom";

const OurIndustriesComponents = ({isReverse,logo,img,description,link}) => {
  return (
    <>
      <div className={`industries ${isReverse ? "horizontal" : ""}`}>
        <div className="industries_logo common_padding">
          <img
            src={logo}
            alt="industries_logo_1"
          />
        </div>
        <div className="industries_image common_padding">
          <img
            src={img}
            className="left_border"
            alt="industries_image_1"
          />
        </div>
        <div className="industries_contant common_padding">
          <div className="our_industries_para">
            <p>
              {description}
            </p>
          </div>
          <div className="our_industries_link">
            <Link to="/">{link}</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurIndustriesComponents;
