import React from "react";
import OurIndustriesComponents from "../common/OurIndustriesComponents";
import { about_us_page_marry_query } from "../../Data";

const AboutUsMarryCurie = () => {
  return (
    <>
      <section className="marie_query_section">
        <div className="container">
          <OurIndustriesComponents
            logo={about_us_page_marry_query.logo}
            img={about_us_page_marry_query.img}
            description={about_us_page_marry_query.description}
            link={about_us_page_marry_query.link}
          />
        </div>
      </section>
    </>
  );
};

export default AboutUsMarryCurie;
