import React from "react";
import OurEthosComponents from "./OurEthosComponents";
import {
  about_us_page_ethos_heading,
  our_ethos_card,
} from "../../Data";

const AboutUsEthos = ({}) => {
  return (
    <>
      <section className="our_ethos_section">
        <div className="container">
          <div className="our_ethos_part">
            <div className="our_ethos_title">
              <h2>{about_us_page_ethos_heading.title}</h2>
              <p>{about_us_page_ethos_heading.description}</p>
            </div>
            <div className="our_ethos_service">
              <div className="our_ethos_row">
                {our_ethos_card &&
                  our_ethos_card.map((ethos) => {
                    const { logo, post, description } = ethos || {};
                    return (
                      <OurEthosComponents
                        logo={logo}
                        post={post}
                        description={description}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUsEthos;
