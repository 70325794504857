import React from "react";
import SustainabilityContantComponents from "./SustainabilityContantComponents";
import {
  sustainability_page_content_data
} from "../../Data";

const SustainabilityContant = ({}) => {
  return (
    <>
      <section className="Sustainability_contant_section">
        <div className="container">
          <div className="Sustainability_contant_part">
            {sustainability_page_content_data &&
              sustainability_page_content_data.map((content,index) => {

                return (
                  <SustainabilityContantComponents
                  isReverse={index % 2 === 1 ? true : false}
                    img={content.contentImg}
                    descriptionOne={content.contentDescriptionOne}
                    descriptionTwo={content.contentDescriptionTwo}
                    descriptionThree={content.contentDescriptionThree}
                  />
                );
              })}
            {/* <SustainabilityContantComponents
              isReverse={false}
              sustainabilityContantImg={sustainability_page_content1.contentImg}
              sustainabilityContantDescriptionOne={
                sustainability_page_content1.contentDescriptionOne
              }
              sustainabilityContantDescriptionTwo={
                sustainability_page_content1.contentDescriptionTwo
              }
              sustainabilityContantDescriptionThree={
                sustainability_page_content1.contentDescriptionThree
              }
            />

            <SustainabilityContantComponents
              isReverse={true}
              sustainabilityContantImg={sustainability_page_content2.contentImg}
              sustainabilityContantDescriptionOne={
                sustainability_page_content2.contentDescriptionOne
              }
              sustainabilityContantDescriptionTwo={
                sustainability_page_content2.contentDescriptionTwo
              }
              sustainabilityContantDescriptionThree={
                sustainability_page_content2.contentDescriptionThree
              }
            />

            <SustainabilityContantComponents
              isReverse={false}
              sustainabilityContantImg={sustainability_page_content3.contentImg}
              sustainabilityContantDescriptionOne={
                sustainability_page_content3.contentDescriptionOne
              }
              sustainabilityContantDescriptionTwo={
                sustainability_page_content3.contentDescriptionTwo
              }
              sustainabilityContantDescriptionThree={
                sustainability_page_content3.contentDescriptionThree
              }
            /> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default SustainabilityContant;
