import React from 'react'
import OurIndustriesComponents from './OurIndustriesComponents'
import { our_industries_data } from '../../Data'

const OurIndustries=()=> {
  return (
    <>
        <section className="our_industries_section">
        <div className="container">
          <div className="our_industries">
            <div className="our_industries_title">
              <h2>
                Our <span className="active">Industries</span>
              </h2>
            </div>
            {
              our_industries_data && our_industries_data.map((indusries,index)=>{
                return (
                  <OurIndustriesComponents
                    isReverse={index % 2 === 1 ? true : false}
                    logo={indusries.industriesLogo}
                    img={indusries.industriesImg}
                    description={indusries.industriesDescription}
                    link={indusries.industriesLink}
                  />
                );
              })
            }
            
          </div>
        </div>
      </section>
    </>
  )
}

export default OurIndustries

