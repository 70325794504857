import React, { useState } from "react";
import { contact_us_page_form } from "../../Data";

const ContactUsForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [business, setBusiness] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = () => {
    console.log({ firstName, lastName, email, business, message });
    setFirstName("");
    setLastName("");
    setEmail("");
    setBusiness("");
    setMessage("");
  };

  return (
    <>
      <section className="contact_us_section">
        <div className="container">
          <div className="contact_us_part">
            <div className="contact_us_form">
              <div className="form_header">
                <h1>{contact_us_page_form.title}</h1>
              </div>
              <div className="form_detail">
                <input
                  type="text"
                  placeholder="First_Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Last_Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Business"
                  value={business}
                  onChange={(e) => setBusiness(e.target.value)}
                />
              </div>
              <div className="message_detail">
                <input
                  type="text"
                  placeholder="Enter Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <div className="save_information">
                <button className="save_button" onClick={handleSubmit}>
                  {contact_us_page_form.button}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUsForm;
