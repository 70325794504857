import React from 'react'
import AboutUsBanner from '../common/AboutUsBanner'
import AboutUsDescript from './AboutUsDescript'
import Brand from '../common/Brand'
import AboutUsMarryCurie from './AboutUsMarryCurie'
import AboutUsEthos from './AboutUsEthos'
import OurSector from '../common/OurSector'
import { about_us_page_marry_query, brandImageData, our_ethos_card} from '../../Data'

const AboutUsPage=()=> {
  return (
    <>
        <AboutUsBanner/>
        <AboutUsDescript/>
        <Brand imgData={brandImageData}/>
        <AboutUsMarryCurie />
        <AboutUsEthos/>
        <OurSector/>
    </>
  )
}

export default AboutUsPage
