import React from "react";
import AboutUsComponent from "./AboutUsComponent";
import Brand from "../common/Brand";
import OurIndustries from "../common/OurIndustries";
import OurBoard from "../common/OurBoard";
import OurSector from "../common/OurSector";
import { brandImageData, home_page_banner_section} from "../../Data";


const DashboardPage = () => {
  return (
    <>
      <section className="section_hero_banner">
        <div className="hero_banner_img">
          <img src="./assets/images/bg image.png" alt="background_image" />
          <span className="bg_left_shap" />
        </div>
        <div className="hero_banner_text">
          <div className="container">
            <div className="banner_text">
              <img src={home_page_banner_section.logo} alt="banner_text" />
              <p>
               {home_page_banner_section.title}
              </p>
            </div>
          </div>
        </div>
        <div className="slider_menu">
          <div className="slider_number">
            <h6>{home_page_banner_section.buttonNumber1}</h6>
            <h6 className="active two"> {home_page_banner_section.buttonNumber2}</h6>
            <h6 className="three"> {home_page_banner_section.buttonNumber3}</h6>
            <h6 className="four"> {home_page_banner_section.buttonNamber4}</h6>
          </div>
          <div className="slider_name">
            {home_page_banner_section.buttonName}
            <img src={home_page_banner_section.defenceButtonArrow} />
          </div>
        </div>
      </section>
      <AboutUsComponent/>
      <Brand imgData={brandImageData}/>
      <OurIndustries/>
      <OurBoard/>
      <OurSector/>
    </>
  );
};

export default DashboardPage;
