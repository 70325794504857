import React from "react";
import { about_us_page_content_description_1, about_us_page_content_description_2, about_us_page_content_description_3, about_us_page_content_description_4, about_us_page_content_description_5, about_us_page_content_title } from "../../Data";

const AboutUsDescript = () => {
  return (
    <>
      <section className="about_us_contant_section">
        <div className="container">
          <div className="about_us_question">
            <h4>
             {about_us_page_content_title.title}
            </h4>
            <p>
              {about_us_page_content_description_1.title}
            </p>
            <p>
              {about_us_page_content_description_2.title}
            </p>
            <p>
             {about_us_page_content_description_3.title}
            </p>
            <p>
              {about_us_page_content_description_4.title}
            </p>
            <p>
              {about_us_page_content_description_5.title}
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUsDescript;
