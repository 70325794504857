// navbar data

export const navbar_data={
    logo:process.env.PUBLIC_URL + '/assets/images/logo.png',
    menu1:"Home",
    menu2:"About Us",
    menu3:" Our Vision",
    menu4:"Our Group",
    menu5:"Sustainability",
    menu6:" News",
    menu7:"Contact Us"
}

// first footer part

export const home_page_footer_details={
    location:"Headquarters",
    address:"1 Little Drum Road Glasgow, Westfield, G68 9LH",
    telephone:"Telephone",
    telephoneNumber:"0141 773 2166",
    mail:"Email",
    mailId:"Sales@bealgroup.co.uk "
}

// second footer part

export const left_social_media_footer={
    linkdinImg:"./assets/images/linkdin.png",
    facebookImg:"./assets/images/facebook.png"
}

export const right_social_media_footer={
    img1:"./assets/images/footer_right_img1.png",
    img2:"./assets/images/footer_right_img2.png",
    img3:"./assets/images/footer_right_img3.png"
}

// Home page dynamic code

export const home_page_banner_section={
    logo:"./assets/images/Group 499.png",
    title:" A family controlled business passionate about its products &amp; service levels yet large enough to cope with all your requirements based in Scotland, covering the UK and the world.",
    buttonName:"Defence",
    buttonNumber1:"1",
    buttonNumber2:"2",
    buttonNumber3:"3",
    buttonNamber4:"4",
    defenceButtonArrow:"./assets/images/icons/right-arrow.svg"
}

export const abouts_paragraph_Home_01 ={
    title: "Sed ut perspiciatis unde omnis iste natus error sit vmaccusantium doloremque laudantium, totam rem aperiam, eaqueipsa quae ab illo inventore veritatis et quasi architecto beaevitae dicta suntfg explicabo. Nemo enim ipsa voluptatem quiavoluptd aspernatur aut odit aut fugit, sed quia consequunturmagni dolores eos qui ratione voluptatem sequi nesciunt.",
    button:"Learn More",
    underImg:"./assets/images/about_us.png",
    onImg:"./assets/images/add image.png"
}

export const abouts_paragraph_Home_02 ={
    title: "Neque porro quisquam est, qui dolorem ipsum quia dolor sitamet, consectetur, adipisci velit, sed quia non numquam eiustempora incidunt ut labore et dolore magnam voluptatem."
}

export const abouts_paragraph_Home_03 ={
    title: "At vero eos et accusam excepturi sint occaecati cupiditate non provident, similique sunt in culp voluptas sit aspernatur autodit aut fugit, consequuntur magni dolores eos qui rationevoluptatem sequi nesciunt."
}

// home page brand section
export const brandImageData=[
    {
        title:"",
        image:"./assets/images/spor-logo.png"
    },
    {
        title:"",
        image:"./assets/images/cbr-logo.png"
    },
    {
        title:"",
        image:"./assets/images/Group 438.png"
    },
    {
        title:"",
        image:"./assets/images/RD-Logo (1).png"
    },
    {
        title:"",
        image:"./assets/images/tonybeal-logo.png"
    },
    {
        title:"",
        image:"./assets/images/Group 438.png"
    }
]

// home page our industries

export const our_industries_data=[
    {
        industriesLogo:"./assets/images/industries_logo_1.png",
        industriesImg:"./assets/images/industries_image_1.png",
        industriesDescription:" Rhino Defence  brings together the best of both worlds: Rhino-UK and Tony Beal Ltd, both part of The Beal Group, who have Linklong tradition of manufacturing quality approved finished textile based items for the UK &amp; International defence market. Serving the armed forces for more than 25 years.",
        industriesLink:"https://rhino-defence.co.uk"
    },
    {
        industriesLogo:"./assets/images/industries_logo_2.png",
    industriesImg:"./assets/images/industries_image_2.png",
    industriesDescription:" Rhino Defence  brings together the best of both worlds: Rhino-UK and Tony Beal Ltd, both part of The Beal Group, who have Linklong tradition of manufacturing quality approved finished textile based items for the UK &amp; International defence market. Serving the armed forces for more than 25 years.",
    industriesLink:"https://rhino-defence.co.uk"
    },
    {
        industriesLogo:"./assets/images/industries_logo_3.png",
    industriesImg:"./assets/images/industries_image_3.png",
    industriesDescription:" Rhino Defence  brings together the best of both worlds: Rhino-UK and Tony Beal Ltd, both part of The Beal Group, who have Linklong tradition of manufacturing quality approved finished textile based items for the UK &amp; International defence market. Serving the armed forces for more than 25 years.",
    industriesLink:"https://rhino-defence.co.uk"
    },
    {
        industriesLogo:"./assets/images/industries_logo_4.png",
    industriesImg:"./assets/images/industries_image_4.png",
    industriesDescription:" Rhino Defence  brings together the best of both worlds: Rhino-UK and Tony Beal Ltd, both part of The Beal Group, who have Linklong tradition of manufacturing quality approved finished textile based items for the UK &amp; International defence market. Serving the armed forces for more than 25 years.",
    industriesLink:"https://rhino-defence.co.uk"
    },
    {
        industriesLogo:"./assets/images/industries_logo_5.png",
        industriesImg:"./assets/images/industries_image_5.png",
        industriesDescription:" Rhino Defence  brings together the best of both worlds: Rhino-UK and Tony Beal Ltd, both part of The Beal Group, who have Linklong tradition of manufacturing quality approved finished textile based items for the UK &amp; International defence market. Serving the armed forces for more than 25 years.",
        industriesLink:"https://rhino-defence.co.uk"
    },
    {
        industriesLogo:"./assets/images/industries_logo_6.png",
        industriesImg:"./assets/images/industries_image_6.png",
        industriesDescription:" Rhino Defence  brings together the best of both worlds: Rhino-UK and Tony Beal Ltd, both part of The Beal Group, who have Linklong tradition of manufacturing quality approved finished textile based items for the UK &amp; International defence market. Serving the armed forces for more than 25 years.",
        industriesLink:"https://rhino-defence.co.uk"
    },  
]
export const home_page_our_industries_data1={
    industrieLogo:"./assets/images/industries_logo_1.png",
    industrieImg:"./assets/images/industries_image_1.png",
    industrieDescription:" Rhino Defence  brings together the best of both worlds: Rhino-UK and Tony Beal Ltd, both part of The Beal Group, who have Linklong tradition of manufacturing quality approved finished textile based items for the UK &amp; International defence market. Serving the armed forces for more than 25 years.",
    industrieLink:"https://rhino-defence.co.uk"

}

export const home_page_our_industries_data2={
    industrieLogo:"./assets/images/industries_logo_2.png",
    industrieImg:"./assets/images/industries_image_2.png",
    industrieDescription:" Rhino Defence  brings together the best of both worlds: Rhino-UK and Tony Beal Ltd, both part of The Beal Group, who have Linklong tradition of manufacturing quality approved finished textile based items for the UK &amp; International defence market. Serving the armed forces for more than 25 years.",
    industrieLink:"https://rhino-defence.co.uk"
}

export const home_page_our_industries_data3={
    industrieLogo:"./assets/images/industries_logo_3.png",
    industrieImg:"./assets/images/industries_image_3.png",
    industrieDescription:" Rhino Defence  brings together the best of both worlds: Rhino-UK and Tony Beal Ltd, both part of The Beal Group, who have Linklong tradition of manufacturing quality approved finished textile based items for the UK &amp; International defence market. Serving the armed forces for more than 25 years.",
    industrieLink:"https://rhino-defence.co.uk"
}

export const home_page_our_industries_data4={
    industrieLogo:"./assets/images/industries_logo_4.png",
    industrieImg:"./assets/images/industries_image_4.png",
    industrieDescription:" Rhino Defence  brings together the best of both worlds: Rhino-UK and Tony Beal Ltd, both part of The Beal Group, who have Linklong tradition of manufacturing quality approved finished textile based items for the UK &amp; International defence market. Serving the armed forces for more than 25 years.",
    industrieLink:"https://rhino-defence.co.uk"
}

export const home_page_our_industries_data5={
    industrieLogo:"./assets/images/industries_logo_5.png",
    industrieImg:"./assets/images/industries_image_5.png",
    industrieDescription:" Rhino Defence  brings together the best of both worlds: Rhino-UK and Tony Beal Ltd, both part of The Beal Group, who have Linklong tradition of manufacturing quality approved finished textile based items for the UK &amp; International defence market. Serving the armed forces for more than 25 years.",
    industrieLink:"https://rhino-defence.co.uk"
}

export const home_page_our_industries_data6={
    industrieLogo:"./assets/images/industries_logo_6.png",
    industrieImg:"./assets/images/industries_image_6.png",
    industrieDescription:" Rhino Defence  brings together the best of both worlds: Rhino-UK and Tony Beal Ltd, both part of The Beal Group, who have Linklong tradition of manufacturing quality approved finished textile based items for the UK &amp; International defence market. Serving the armed forces for more than 25 years.",
    industrieLink:"https://rhino-defence.co.uk"
}


// home page our board

export const our_board_data=[
    {
        img:"./assets/images/card_1.png",
        name:"David J.A. Beal",
        post:"Group Managing Director",
        details:"Having been involved in industrial textiles all his life, David is familiar with almost all levels of textile operation, from weaving through to dyeing, processing, finishing and finally with fabrication - specialising incustomised products, development of products and bulk manufacturing....",
        button:"Read More"
    },
    {
        img:"./assets/images/card_2.png",
        name:"Graham Thom",
        post:"Chairman",
        details:"Having been involved in industrial textiles all his life, David is familiar with almost all levels of textile operation, from weaving through to dyeing, processing, finishing and finally with fabrication - specialising incustomised products, development of products and bulk manufacturing....",
        button:"Read More"
    },
    {
        img:"./assets/images/card_3.png",
        name:"John Anderson",
        post:"Non-Executive Director",
        details:"Having been involved in industrial textiles all his life, David is familiar with almost all levels of textile operation, from weaving through to dyeing, processing, finishing and finally with fabrication - specialising incustomised products, development of products and bulk manufacturing....",
        button:"Read More"
    }
]

// home page our sector part data

export const our_sector_data=[
    {sector1:[
        
        {
            img:"./assets/images/our sector 1.png",
            hoverHeading:"Defence",
            hoverDescription:"Servicing the defence sector with Military bags, tents and camouflage whilst all being MADE in the UK",
            hoverButton:"Visit Rhino Defence"
        },
        {
            img:"./assets/images/our sector 2.png",
            hoverHeading:"Defence",
            hoverDescription:"Servicing the defence sector with Military bags, tents and camouflage whilst all being MADE in the UK",
            hoverButton:"Visit Rhino Defence"
        }
    ],},

    {sector2:[
        {
            img:"./assets/images/our sector 3.png",
            hoverHeading:"Defence",
            hoverDescription:"Servicing the defence sector with Military bags, tents and camouflage whilst all being MADE in the UK",
            hoverButton:"Visit Rhino Defence"
        },
        {
            img:"./assets/images/our sector 4.png",
            hoverHeading:"Defence",
            hoverDescription:"Servicing the defence sector with Military bags, tents and camouflage whilst all being MADE in the UK",
            hoverButton:"Visit Rhino Defence"
        }
    ],},

    {sector3:[
        {
            img:"./assets/images/our sector 1.png",
            hoverHeading:"Defence",
            hoverDescription:"Servicing the defence sector with Military bags, tents and camouflage whilst all being MADE in the UK",
            hoverButton:"Visit Rhino Defence"
        },
        {
            img:"./assets/images/our sector 2.png",
            hoverHeading:"Defence",
            hoverDescription:"Servicing the defence sector with Military bags, tents and camouflage whilst all being MADE in the UK",
            hoverButton:"Visit Rhino Defence"
        }
    ]}
     
]


// ****************************************************************************************************************

// about us page data

export const about_us_page_content_title={
    title:"The Beal group of companies service & supply global requirements whilst being totally committed to UK manufacturing."
}

export const about_us_page_content_description_1={
    title:"The Beal group of companies service & supply global requirements whilst being totally committed to UK manufacturing."
}

export const about_us_page_content_description_2={
    title:"With its two manufacturing bases – one in Scotland the other in the West Midlands, the Beal Group provides service requirements, throughout the UK and also overseas. With a combined production facility of over 75,000 square feet, the group manufactures over 2,500 items per week and has the space and facility to work on equipment & vehicles both inside and outside their factories."
}

export const about_us_page_content_description_3={
    title:"The group is focused on manufacturing quality products, engineered to meet the customer’s requirements or specification. Where necessary, our design team will work to find a solution to meet your specific needs, requirements and lead times.Should branding be required, our print team is on hand to look at printing solutions to meet your needs."
}

export const about_us_page_content_description_4={
    title:"Our service teams are committed to being as efficient as possible, understanding the customer or user requirement and delivering what is needed.."
}

export const about_us_page_content_description_5={
    title:"With a combined workforce of over 85 staff and over 35 years of experience, the group service a wide range of sectors, supplying simple & complicated products from 1 off single customised items to mass bulk production."
}

// about us page marry query section

export const about_us_page_marry_query={
    logo:"./assets/images/about_us_image/marey curie.png",
    img:"./assets/images/about_us_image/marey curie peple.png",
    description:" The Beal Group of companies supports Marie Curie as their nominated principle charity..\n  Please contact either us or Marie Curie if you wish information on the valuable services that they provide .",
    link:"View Gallery"
}

// about us page our ethos section

export const about_us_page_ethos_heading={
    title:"Our Ethos",
    description:"The Beal Group are passionate and committed to providing Superior Craftmanship and Quality to all of our Clients both in the UK and Internationally in the manufacture of our products, coupled with Outstanding Customer Service at all times throughout our Group of companies."
}

export const our_ethos_card=[
    {
        logo:"./assets/images/about_us_image/manufacturering.png",
        post:"Manufacturing Excellence",
        description:"We are passionate about designing and manufacturing covering solutions"
    },
    {
        logo:"./assets/images/about_us_image/service.png",
        post:"Ownership",
        description:"We take ownership of our decisions and actions"
    },
    {
        logo:"./assets/images/about_us_image/integrity.png",
        post:"Integrity",
        description:"We do the right thing – every time"
    },
    {
        logo:"./assets/images/about_us_image/customer service.png",
        post:"Customer Satisfaction",
        description:"Always striving to surpass customer expectation"
    },
    {
        logo:"./assets/images/about_us_image/public.png",
        post:"Team Work",
        description:"We are team players in every aspect of what we do"
    },
    {
        logo:"./assets/images/about_us_image/now.png",
        post:"Now",
        description:"We operate with a sense of urgency and discipline"
    }
]

// *********************************************************************************************************

// our vision page data 

export const our_vision_lorem_epsum=[
    {
        loremEpsumLogo:"./assets/images/our_vision_image/lorem .png",
        loremEpsumName:"Lorem Ipsum",
        loremEpsumContent:" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem more recently with desktop publishing software like Aldus PageMaker Ipsum."
    },
    {
        loremEpsumLogo:"./assets/images/our_vision_image/lorem .png",
        loremEpsumName:"Lorem Ipsum",
        loremEpsumContent:" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem more recently with desktop publishing software like Aldus PageMaker Ipsum."
    },
     {
        loremEpsumLogo:"./assets/images/our_vision_image/lorem .png",
        loremEpsumName:"Lorem Ipsum",
        loremEpsumContent:" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem more recently with desktop publishing software like Aldus PageMaker Ipsum."
    }
]
// ************************************************************************************************************

// sustainability data

export const sustainability_page_content_data=[
    {
        contentImg:"./assets/images/sustainability_part_1.png",
        contentDescriptionOne:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown  printer took a galley of type and scrambled it to make a type specimen book.",
        contentDescriptionTwo:"It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.  It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        contentDescriptionThree:" Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    },
    {
        contentImg:"./assets/images/sustainability_part_2.png",
        contentDescriptionOne:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown  printer took a galley of type and scrambled it to make a type specimen book.",
        contentDescriptionTwo:"It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.  It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        contentDescriptionThree:" Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    },
    {
        contentImg:"./assets/images/sustainability_part_3.png",
        contentDescriptionOne:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown  printer took a galley of type and scrambled it to make a type specimen book.",
        contentDescriptionTwo:"It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.  It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        contentDescriptionThree:" Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    }
]

// ******************************************************************************************************

// news page data

export const news_page_head_news1={
    img:"./assets/images/news_section_1_img.png",
    date:"April 4, 2022",
    title:"Lorem Ipsum is simply dummy text of the",
    description:"Lorem Ipsum is simply dummy text of the printing an typesetting industry. Lorem Ipsum has the industry'sstandard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
}

export const news_page_sub_news_data=[
    {
        img:"./assets/images/news_section_2_img1.png",
        date:"April 4, 2022",
        title:"Lorem Ipsum is simply dummy text of the",
        description:"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of  type and scrambled it to make a type specimen book."
    },
    {
        img:"./assets/images/news_section_2_img2.png",
        date:"April 4, 2022",
        title:"Lorem Ipsum is simply dummy text of the",
        description:"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of  type and scrambled it to make a type specimen book."
    },
    {
        img:"./assets/images/news_section_2_img3.png",
        date:"April 4, 2022",
        title:"Lorem Ipsum is simply dummy text of the",
        description:"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of  type and scrambled it to make a type specimen book."
    },
    {
        img:"./assets/images/news_section_2_img4.png",
        date:"April 4, 2022",
        title:"Lorem Ipsum is simply dummy text of the",
        description:"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of  type and scrambled it to make a type specimen book."
    },
    {
        img:"./assets/images/news_section_2_img5.png",
        date:"April 4, 2022",
        title:"Lorem Ipsum is simply dummy text of the",
        description:"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of  type and scrambled it to make a type specimen book."
    },
    {
        img:"./assets/images/news_section_2_img6.png",
        date:"April 4, 2022",
        title:"Lorem Ipsum is simply dummy text of the",
        description:"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of  type and scrambled it to make a type specimen book."
    },
    {
        img:"./assets/images/news_section_2_img7.png",
        date:"April 4, 2022",
        title:"Lorem Ipsum is simply dummy text of the",
        description:"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of  type and scrambled it to make a type specimen book."
    },
    {
        img:"./assets/images/news_section_2_img8.png",
        date:"April 4, 2022",
        title:"Lorem Ipsum is simply dummy text of the",
        description:"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of  type and scrambled it to make a type specimen book."
    },
    {
        img:"./assets/images/news_section_2_img9.png",
        date:"April 4, 2022",
        title:"Lorem Ipsum is simply dummy text of the",
        description:"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of  type and scrambled it to make a type specimen book."
    }
]

// contact us page data 

export const contact_us_page_form={
    title:"The Beal Group",
    button:"Save"
}

export const contact_us_page_mobile={
    img:"./assets/images/mobile.png",
    number:"0141 773 2166"
}

export const contact_us_page_mail={
  img:"./assets/images/email.png",
  mail:"sales@bealgroup.co.uk"
}

export const contact_us_page_location={
    img:"./assets/images/location.png",
    address:"1 Little Drum Road Glasgow Westfield G68 9LH"
}