import React from 'react'
import OurVisionLoremCircle from './OurVisionLoremCircle'
import OurSector from '../common/OurSector'
import AboutUsBanner from '../common/AboutUsBanner'
import { our_vision_lorem_epsum } from '../../Data'

const OurVisionPage=()=> {
  return (
    <>
        <AboutUsBanner/>
        <OurVisionLoremCircle loremEpsum={our_vision_lorem_epsum} />
        <OurSector/>
    </>
  )
}

export default OurVisionPage
