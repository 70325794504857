import React from 'react'
import SustainabilityContant from './SustainabilityContant'
import OurSector from '../common/OurSector'
import AboutUsBanner from '../common/AboutUsBanner'

const SustainabilityPage=()=> {
  return (
    <>
        <AboutUsBanner/>
        <SustainabilityContant/>
        <OurSector/>

    </>
  )
}

export default SustainabilityPage
