import React from "react";

const OurBoardComponent = ({ img, name, post, details, button }) => {
  return (
    <>
      <div className="card">
        <div className="director_contant">
          <img src={img} alt="card_1" />
          <h3>{name}</h3>
          <h6>{post}</h6>
          <p>{details}</p>
        </div>
        <div className="read_more_btn">
          <button className="button-arrow">
            {button}
            <img src="./assets/images/icons/right-arrow.svg" />
          </button>
        </div>
      </div>
    </>
  );
};

export default OurBoardComponent;
