import React from "react";
import {
  our_board_data
} from "../../Data";
import OurBoardComponent from "./OurBoardComponent";

const OurBoard = ({}) => {
  return (
    <>
      <section className="our_board_section">
        <div className="container">
          <div className="our_board_part">
            <div className="Our_board_title">
              <h2>Our Board</h2>
            </div>
            <div className="our_board_card ">
              {our_board_data &&
                our_board_data.map((board) => {
                  const { img, name, post, details, button } = board || {};
                  return (
                    <OurBoardComponent
                      img={img}
                      name={name}
                      post={post}
                      details={details}
                      button={button}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurBoard;
