import React from "react";

const Brand = (props) => {
  return (
    <>
      <section className="company_logo_section">
        <div className="container">
          <div className="company_part">
            {
              props.imgData.map((res)=>{
                return <img src={res.image} alt={`image_${res.title}`} />
              })
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default Brand;
