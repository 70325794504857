import React from "react";

const NewsSubNewsComponents = ({image,date,title,description}) => {
  return (
    <>
      <div className="news_card">
        <div className="mini_news_image">
          <img src={image} />
        </div>
        <div className="mini_news_contant">
          <h6>{date}</h6>
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
      </div>
    </>
  );
};

export default NewsSubNewsComponents;
