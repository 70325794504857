import React from "react";
import { abouts_paragraph_Home_01,abouts_paragraph_Home_02, abouts_paragraph_Home_03} from "../../Data";

const AboutUsComponent = () => {
  return (
    <>
      <section className="about_us_section">
        <div className="container">
          <div className="about_us_part">
            <div className="about_us">
              <div className="about_us_title">
                <h2>
                  About <span className="active">Us</span>
                </h2>
              </div>
              <div className="about_us_contant">
                <p>
                  {abouts_paragraph_Home_01.title}
                </p>
                <p>
                {abouts_paragraph_Home_02.title}
                </p>
                <p>
                {abouts_paragraph_Home_03.title}
                </p>
                <div className="learn_more_botton">
                  <button>
                    {abouts_paragraph_Home_01.button}
                    <img src="./assets/images/icons/right-arrow.svg"/>
                  </button>
                </div>
              </div>
            </div>
            <div className="about_us_img_right">
              <div className="about_us_under_image">
                <img src={abouts_paragraph_Home_01.underImg} alt />
              </div>
              <div className="about_us_on_image">
                <img src={abouts_paragraph_Home_01.onImg} alt="on_image" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUsComponent;
