import React from "react";
import { our_sector_data } from "../../Data";

const OurSector = ({ img, hoverHeading, hoverDescription, hoverButton }) => {
  return (
    <>
      <section className="our_sectors">
        <div className="container">
          <div className="our_sectors_row">
            <div className="our_sectors_title">
              <h2>
                Our <b className="active">Sectors</b>
              </h2>
            </div>
            <div className="our_sectors_images">
              {our_sector_data.map((data) => {
                return (
                  <div className="our_sectors_box">
                    {data.sector1?.map((image) => {
                      return (
                        <div className="our_sectors_img">
                          <img src={image.img} />
                          <div className="text_hover">
                            <h3>{image.hoverHeading}</h3>
                            <p>{image.hoverDescription}</p>
                            <div className="text_hover_button">
                              <h6> {image.hoverButton}</h6>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {data.sector2?.map((image) => {
                      return (
                        <div className="our_sectors_img">
                          <img src={image.img} />
                          <div className="text_hover">
                            <h3>{image.hoverHeading}</h3>
                            <p>{image.hoverDescription}</p>
                            <div className="text_hover_button">
                              <h6> {image.hoverButton}</h6>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    {data.sector3?.map((image) => {
                      return (
                        <div className="our_sectors_img">
                          <img src={image.img} />
                          <div className="text_hover">
                            <h3>{image.hoverHeading}</h3>
                            <p>{image.hoverDescription}</p>
                            <div className="text_hover_button">
                              <h6> {image.hoverButton}</h6>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}

              {/* <div className="our_sectors_img">
                  <img src={home_page_our_sector1.img} />
                  <div className="text_hover">
                    <h3>{home_page_our_sector1.hoverHeading}</h3>
                    <p>{home_page_our_sector1.hoverDescription}</p>
                    <div className="text_hover_button">
                      <h6> {home_page_our_sector1.hoverButton}</h6>
                    </div>
                  </div>
                </div>
                <div className="our_sectors_img">
                  <img src={home_page_our_sector2.img} />
                  <div className="text_hover">
                    <h3>{home_page_our_sector2.hoverHeading}</h3>
                    <p>{home_page_our_sector2.hoverDescription}</p>
                    <div className="text_hover_button">
                      <h6>{home_page_our_sector2.hoverButton}</h6>
                    </div>
                  </div>
                </div> */}

              {/* </div>
              <div className="our_sectors_box">
                <div className="our_sectors_img">
                  <img src={home_page_our_sector3.img} />
                  <div className="text_hover">
                    <h3>{home_page_our_sector3.hoverHeading}</h3>
                    <p>{home_page_our_sector3.hoverDescription}</p>
                    <div className="text_hover_button">
                      <h6>{home_page_our_sector3.hoverButton}</h6>
                    </div>
                  </div>
                </div>
                <div className="our_sectors_img">
                  <img src={home_page_our_sector4.img} />
                  <div className="text_hover">
                    <h3>{home_page_our_sector4.hoverHeading}</h3>
                    <p>{home_page_our_sector4.hoverDescription}</p>
                    <div className="text_hover_button">
                      <h6>{home_page_our_sector4.hoverButton}</h6>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="our_sectors_box">
                <div className="our_sectors_img">
                  <img src={home_page_our_sector5.img} />
                  <div className="text_hover">
                    <h3>{home_page_our_sector5.hoverHeading}</h3>
                    <p>{home_page_our_sector5.hoverDescription}</p>
                    <div className="text_hover_button">
                      <h6>{home_page_our_sector5.hoverButton}</h6>
                    </div>
                  </div>
                </div>
                <div className="our_sectors_img">
                  <img src={home_page_our_sector6.img} />
                  <div className="text_hover">
                    <h3>{home_page_our_sector6.hoverHeading}</h3>
                    <p>{home_page_our_sector6.hoverDescription}</p>
                    <div className="text_hover_button">
                      <h6>{home_page_our_sector6.hoverButton}</h6>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurSector;
