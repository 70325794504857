import React from "react";
import { NavLink } from "react-router-dom";
import { navbar_data } from "../Data";


const Header = () => {
  return (
    <div>
      <header className="section_header">
        <div className="container">
          <div className="header">
            <div className="header_logo">
            <img src={navbar_data.logo} /> 
            </div>
            <div className="header_menu">
              <div className="humburger_menu">
                <input id="menu_toggle" type="checkbox" />
                <label className="menu_btn" for="menu_toggle">
                  <span></span>
                </label>
                <ul className="menu_items menu_box">
                  <li>
                    <NavLink to="/" className="navbar_item">
                      {navbar_data.menu1}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/about-us" className="navbar_item">
                    {navbar_data.menu2}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/our-vision" className="navbar_item">
                    {navbar_data.menu3}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/our-group" className="navbar_item">
                    {navbar_data.menu4}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/sustainability" className="navbar_item">
                    {navbar_data.menu5}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/news" className="navbar_item">
                    {navbar_data.menu6}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact-us" className="navbar_item">
                    {navbar_data.menu7}
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
