import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const AboutUsBanner = () => {
  const [imgUrl, setImgUrl] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    switch (path) {
      case "/about-us": {
        setImgUrl("./assets/images/about_us_image/banner.png");
        setTitle("About Us");
        setDescription(
          "The Beal Group of Companies: A family controlled business, passionate about its products &amp; service levels and large enough to cope with all requirements."
        );
        break;
      }

      case "/our-vision": {
        setImgUrl("./assets/images/our_vision_image/our_vision_background_img.png");
        setTitle("Our Vision");
        setDescription(
          "The Beal Group of Companies: A family controlled business, passionate about its products &amp; service levels and large enough to cope with all requirements."
        );
        break;
      }

      case "/our-group": {
        setImgUrl("./assets/images/our_vision_image/our_vision_background_img.png");
        setTitle("Our Group");
        setDescription(
          "The Beal Group of Companies: A family controlled business, passionate about its products &amp; service levels and large enough to cope with all requirements."
        );
        break;
      }

      case "/sustainability": {
        setImgUrl("./assets/images/Sustainability_backgroundimg.png");
        setTitle("Sustainability");
        setDescription(
          "The Beal Group of Companies: A family controlled business, passionate about its products &amp; service levels and large enough to cope with all requirements."
        );
        break;
      }

      case "/news": {
        setImgUrl("./assets/images/news_backgroundimg.png");
        setTitle("News");
        setDescription(
          "The Beal Group of Companies: A family controlled business, passionate about its products &amp; service levels and large enough to cope with all requirements."
        );
        break;
      }

      case "/contact-us": {
        setImgUrl("./assets/images/news_backgroundimg.png");
        setTitle("Contact Us");
        setDescription(
          "The Beal Group of Companies: A family controlled business, passionate about its products &amp; service levels and large enough to cope with all requirements."
        );
        break;
      }
    }
  });

  return (
    <>
      <section className="about_us_hero_banner_section">
        <div className="about_us_hero_banner_img">
          <img src={imgUrl} alt="background_image" />
        </div>
        <div className="about_us_banner_text">
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
      </section>
    </>
  );
};

export default AboutUsBanner;
