import React from "react";

const OurVisionLoremCircle = ({ loremEpsum }) => {
  return (
    <>
      <section className="lorem_epsum_circle">
        <div className="container">
          <div className="lorem_epsum_part">
            {loremEpsum.map((lorem,index) => {
              return (
                <>
                  <span className={`lorem_epsum${index == 2 ? 'Before' : 'After'}`} />
                  <div className="lorem_epsum">
                    <img src={lorem.loremEpsumLogo} />
                    <h2>{lorem.loremEpsumName}</h2>
                    <p>{lorem.loremEpsumContent}</p>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default OurVisionLoremCircle;
