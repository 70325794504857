import React from "react";

const OurEthosComponents = ({ logo, post, description }) => {
  return (
    <>
      <div className="ethos_card">
        <img src={logo} alt />
        <h2>{post}</h2>
        <p>{description}</p>
      </div>
    </>
  );
};

export default OurEthosComponents;
