import React from "react";
import { contact_us_page_location, contact_us_page_mail, contact_us_page_mobile } from "../../Data";

const ContactUsInfo = () => {
  return (
    <>
      <section className="contact_data_section">
        <div className="container">
          <div className="contact_data_part">
            <div className="contact_data">
              <div className="mobile">
                <img src={contact_us_page_mobile.img} />
                <h4>{contact_us_page_mobile.number}</h4>
              </div>
              <div className="mobile">
                <img src={contact_us_page_mail.img} alt />
                <h4>{contact_us_page_mail.mail}</h4>
              </div>
              <div className="mobile">
                <img src={contact_us_page_location.img} alt />
                <h4>{contact_us_page_location.address}</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUsInfo;
