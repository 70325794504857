import React from "react";
import { news_page_head_news1 } from "../../Data";

const NewsHeadNews = () => {
  return (
    <>
      <section className="main_news_section">
        <div className="container">
          <div className="main_news_part">
            <div className="main_news">
              <div className="main_news_image">
                <img
                  src={news_page_head_news1.img}
                  alt="news_image"
                />
              </div>
              <div className="main_news_contant">
                <h6>{news_page_head_news1.date}</h6>
                <h2>{news_page_head_news1.title}</h2>
                <p>
                  {news_page_head_news1.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewsHeadNews;
