import React from 'react'
import NewsHeadNews from './NewsHeadNews'
import NewsSubNews from './NewsSubNews'
import AboutUsBanner from '../common/AboutUsBanner'

const NewsPage=()=> {
  return (
    <>
        <AboutUsBanner/>
        <NewsHeadNews/>
        <NewsSubNews/>
    </>
  )
}

export default NewsPage
