import React from "react";

const ContactUsMap = () => {
  return (
    <>
      <section className="map_section">
        <div className="container">
          <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2234.598566722506!2d-4.068535784694539!3d55
          .93899678508436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48886866c38e931f%3A0xd1a287ee8ac989a7!2sLittle%20Dru
          m%20Rd%2C%20Cumbernauld%2C%20Glasgow%20G68%209LH%2C%20UK!5e0!3m2!1sen!2sin!4v1676891240165!5m2!1sen!2sin"
              width={600}
              height={450}
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUsMap;
