import React from "react";
import NewsSubNewsComponents from "./NewsSubNewsComponents";
import {news_page_sub_news_data } from "../../Data";


const NewsSubNews = ({}) => {
  return (
    <>
      <section className="mini_news_section">
        <div className="container">
          <div className="mini_news_part">
            <div className="mini_news">

              {
                news_page_sub_news_data && news_page_sub_news_data.map((newsDetail)=>{
                  return(
                    <NewsSubNewsComponents
                    image={newsDetail.img}
                    date={newsDetail.date}
                    title={newsDetail.title}
                    description={newsDetail.description}
                    />
                  )
                })
              }

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewsSubNews;
