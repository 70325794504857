import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DashboardPage from "./modules/dashbord/DashboardPage";
import Layout from "./layout/Layout";
import AboutUsPage from "./modules/about-us/AboutUsPage";
import OurVisionPage from "./modules/our-vision/OurVisionPage";
import OurGroupPage from "./modules/our-group/OurGroupPage";
import SustainabilityPage from "./modules/sustainability/SustainabilityPage";
import NewsPage from "./modules/news/NewsPage";
import ContactUspage from "./modules/contact-us/ContactUspage";



function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<DashboardPage />} />
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route path="/our-vision" element={<OurVisionPage />} />
            <Route path="/our-group" element={<OurGroupPage/>}/>
            <Route path="/sustainability" element={<SustainabilityPage/>}/>
            <Route path="/news" element={<NewsPage/>}/>
            <Route path="/contact-us" element={<ContactUspage/>}/>
          </Route>

          
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
