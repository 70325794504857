import React from "react";

const SustainabilityContantComponents = ({
    isReverse,
    img,descriptionOne , descriptionTwo,descriptionThree
}) => {
  return (
    <>
      <div className={`Sustainability ${isReverse ? "same_direction" : ""}`}>
        <div className="Sustainability_contant_image">
          <img src={img} />
        </div>
        <div className="Sustainability_contant_desciption">
          <p>{descriptionOne}</p>
          <p>{descriptionTwo}</p>
          <p>{descriptionThree}</p>
        </div>
      </div>
    </>
  );
};

export default SustainabilityContantComponents;
