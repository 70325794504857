import React from "react";
import AboutUsBanner from "../common/AboutUsBanner";
import ContactUsForm from "./ContactUsForm";
import ContactUsInfo from "./ContactUsInfo";
import ContactUsMap from "./ContactUsMap";

const ContactUspage = () => {
  return (
    <>
      {/* <ContactUsBanner/> */}
      <AboutUsBanner />
      <ContactUsForm />
      <ContactUsInfo />
      <ContactUsMap />
    </>
  );
};

export default ContactUspage;
